.hover-button {
    background-color: #212c5f;   /* Default background color */
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.hover-button:hover {
    background-color: #212c5f; /* Change color on hover */
    color: #ffb822;
}

.hover-button:disabled {
    opacity: .65;
    cursor: default;
}

.hover-button-no-icon {
    background-color: #212c5f;   /* Default background color */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.hover-button-no-icon:hover {
    background-color: #212c5f; /* Change color on hover */
    color: #ffb822;
}

.hover-white-button {
    background-color: white;   /* Default background color */
    border: none;
    border-radius: 20px;
    padding: 8px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.hover-white-button:hover {
    background-color: rgb(221, 220, 220); /* Change color on hover */
}

.aksi-button {
    background-color: #212c5f;   /* Default background color */
    color: white;
    border: none;
    border-radius: 20px;
    padding: 7px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.aksi-button:hover {
    background-color: #212c5f; /* Change color on hover */
    color: #ffb822;
}

.aksi-button:disabled {
    opacity: .65;
    cursor: default;
}

.blank-button {
    background-color: white;   /* Default background color */
    color: #212c5f;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 20px;
    padding: 8px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.blank-button:hover {
    background-color: rgb(240, 240, 240);
}

.blank-button-no-icon {
    background-color: white;   /* Default background color */
    color: #212c5f;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 5px;
    padding: 8px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.blank-button-no-icon:hover {
    background-color: rgb(240, 240, 240);
}

.user-button {
    background-color: rgba(255, 184, 34, 0.15);   /* Default background color */
    color: #ffb822;
    border: none;
    border-radius: .25rem;
    padding: 10px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.user-button:hover {
    background-color: #212c5f;
    color: white
}

.yellow-button {
    background-color: rgba(255, 184, 34, 0.15);   /* Default background color */
    color: #ffb822;
    border: none;
    border-radius: .25rem;
    padding: 10px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.yellow-button:hover {
    background-color: #ffb822;
    color: black
}

.petunjuk-button {
    background-color: #ffb822;
    color: white;
    border: none;
    border-radius: .25rem;
    padding: 10px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.petunjuk-button:hover {
    background-color: #f8aa00;
    color: white
}

.ebupot-unifikasi-refresh-button {
    background-color: #ffb822;
    color: white;
    border: none;
    border-radius: .9rem;
    padding: 5px 5px;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.ebupot-unifikasi-refresh-button:hover {
    background-color: #f8aa00;
    color: white
}

.cari-pralapor-button {
    background-color: #1dc9b7;
    color: white;
    border: none;
    border-radius: .25rem;
    padding: 10px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.cari-pralapor-button:hover {
    background-color: #1eb3a4;
    color: white
}

.menu-profil-text {
    color: #646c9a;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.menu-profil-text:hover {
    color: blue
}

.menu-data-profil-text-active {
    color: #ffb822;
    border-bottom: 2px solid #ffb822;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    transition: color 0.5s ease; /* Smooth transition on hover */
}

.menu-data-profil-text {
    color: #646c9a;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    transition: color 0.5s ease; /* Smooth transition on hover */
}
  
.menu-data-profil-text:hover {
    color: #ffb822;
    border-bottom: 2px solid #ffb822;
}

.menu-data-kirim-spt-text-active {
    color: #5578eb;
    border-bottom: 2px solid #5578eb;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    transition: color 0.5s ease; /* Smooth transition on hover */
}

.menu-data-kirim-spt-text {
    color: #646c9a;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    transition: color 0.5s ease; /* Smooth transition on hover */
}
  
.menu-data-kirim-spt-text:hover {
    color: #5578eb;
    border-bottom: 2px solid #5578eb;
}

.menu-data-bayar-text-active {
    color: #212c5f;
    border-bottom: 1px solid #212c5f;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    transition: color 0.5s ease; /* Smooth transition on hover */
}

.menu-data-bayar-text {
    color: #646c9a;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    transition: color 0.5s ease; /* Smooth transition on hover */
}

.menu-data-bayar-text:hover {
    color: #212c5f;
    border-bottom: 1px solid #212c5f;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    transition: color 0.5s ease; /* Smooth transition on hover */
}

.menu-data-pralapor-text-active {
    color: #ffb822;
    border-bottom: 1px solid #ffb822;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    transition: color 0.5s ease; /* Smooth transition on hover */
}

.menu-data-pralapor-text {
    color: #646c9a;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    transition: color 0.5s ease; /* Smooth transition on hover */
}

.menu-data-pralapor-text:hover {
    color: #ffb822;
    border-bottom: 1px solid #ffb822;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    transition: color 0.5s ease; /* Smooth transition on hover */
}

.paper-background {
    background-color: white;
    transition: color 0.5s ease; /* Smooth transition on hover */
}

.paper-background:hover {
    background-color: #f0f0f0;
    transition: color 0.5s ease; /* Smooth transition on hover */
}

.icon-ebupot-pph {
    fill: gray;
    cursor: pointer;
    transition: color 0.5s ease; /* Smooth transition on hover */
}

.icon-ebupot-pph:hover {
    fill: blue;
    cursor: pointer;
    transition: color 0.5s ease; /* Smooth transition on hover */
}

.accordion-blue {
    background-color: #a1ccf7;
    color: black !important;
    font-weight: 600;
}

.daftar-dokumen-button {
    background-color: #ffb822;
    color: #212c5f;
    border: none;
    border-radius: .9rem;
    padding: 5px 5px;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.daftar-dokumen-button:hover {
    background-color: #f8aa00;
    color: #212c5f
}

.daftar-dokumen-button:disabled {
    opacity: .65;
    cursor: default;
}

.tambah-daftar-dokumen-button {
    background-color: #1dc9b7;
    color: white;
    border: none;
    border-radius: .9rem;
    padding: 5px 5px;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.tambah-daftar-dokumen-button:hover {
    background-color: #1eb3a4;
    color: white
}

.tambah-daftar-dokumen-button:disabled {
    opacity: .65;
    cursor: default;
}

.upload-sertifikat-elektronik-button {
    background-color: rgba(55,74,251,0.1);
    color: #646c9a;
    border: none;
    border-radius: .2rem;
    padding: 10px;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.upload-sertifikat-elektronik-button:hover {
    background-color: blue;
    color: white
}

.copy-button {
    background-color: white;   /* Default background color */
    color: #646c9a;
    border: 1px solid rgb(211, 211, 211);
    border-radius: 10px;
    padding: 8px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.copy-button:hover {
    background-color: rgb(230, 230, 230); /* Change color on hover */
    color: #646c9a;
}

.delete-button {
    background-color: #fd397a;   /* Default background color */
    color: white;
    border: none;
    border-radius: 20px;
    padding: 7px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.delete-button:hover {
    background-color: #ff1b67; /* Change color on hover */
    color: white;
}

.ebupot-2126-refresh-button {
    background-color: #ffb822;
    color: white;
    border: none;
    border-radius: .9rem;
    padding: 5px 5px;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.ebupot-2126-refresh-button:hover {
    background-color: #f8aa00;
    color: white
}

.ekspor-bupot-button {
    background-color: #1dc9b7;
    color: #212c5f;
    border: none;
    border-radius: .25rem;
    padding: 10px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.ekspor-bupot-button:hover {
    background-color: #1eb3a4;
    color: #212c5f
}

.delete-all-button {
    background-color: #fd397a;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 5px 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.delete-all-button:hover {
    background-color: #ff2870;
    color: white
}

.ebupot-unifikasi-rekam-button {
    background-color: #ffb822;
    color: #212c5f;
    border: none;
    border-radius: .9rem;
    padding: 5px 10px;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
  
.ebupot-unifikasi-rekam-button:hover {
    background-color: #f8aa00;
    color: #212c5f
}